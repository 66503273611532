import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

import { OrbitControls, Html } from "@react-three/drei";
import { Perf } from "r3f-perf";
import Selfinder from "./frontpage/Selfinder";
import DistortBall from "./designElements/DistortBall.";

const Experience = () => {
  const cube = useRef();
  const sphere = useRef();

  useFrame((state, delta) => {
    cube.current.rotation.y += 0.1 * delta;
    cube.current.rotation.x += 0.5 * delta;

    sphere.current.rotation.y -= 0.1 * delta;
  });
  return (
    <>
      {/* <Perf /> */}

      <OrbitControls makeDefault />

      <directionalLight position={[-4, 1, 3]} intensity={1} />
      <ambientLight intensity={1.5} />

      <group position={[6, 0, 0]}>
        <points ref={sphere} scale={3} position={[-3, -1, 0]}>
          <sphereGeometry args={[1, 7, 100]} />
          <pointsMaterial size={0.15} color={"#B59DD1"} />
        </points>

        <mesh ref={cube} scale={0.5} position={[0, 1, 0]}>
          <boxGeometry />
          <meshStandardMaterial color="mediumpurple" wireframe />
        </mesh>

        <DistortBall
          hoverColor="#E7CAE8"
          position={[-0.2, 0, 0]}
          distort={0.5}
          speed={0.9}
        />

        <mesh scale={0.1} position={[-3, -1, 0]}>
          <Html>
            <div className="dimensions">Self</div>
          </Html>
        </mesh>
        <mesh scale={0.1} position={[-5, -1, 0]}>
          <Html>
            <div className="dimensions">Wisdom</div>
          </Html>
        </mesh>

        <mesh scale={0.1} position={[-2, -2, 0]}>
          <Html>
            <div className="dimensions">Balance</div>
          </Html>
        </mesh>
        <mesh scale={0.1} position={[-4, 0, 0]}>
          <Html>
            <div className="dimensions">Purpose</div>
          </Html>
        </mesh>
      </group>

      <Selfinder />
    </>
  );
};

export default Experience;
